.div-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 35px;
}

.header {
  background-color: #00b6de;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  padding: 0 0px 0 120px;
}

.header-text {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  visibility: visible;
}

.header-logo {
  width: 115px;
  height: 73px;
}

.image-orange-balls {
  width: 366px;
  height: 100px;
}

.img-logo {
  width: 200px;
  padding: 20px 0;
}

.div-banner {
  width: 100%;
  display: flex;
  justify-content: center;
  /* background-image: url(../../assets/images/bannerCarrinhoBorrado.png); */
  background-size: 100% 100%;
}

.header-img {
  width: 800px;
  height: 400px;
  margin: -20px 0 -20px 0;
  border-radius: 10px;
  margin-top: 60px;
  /* box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%); */
  box-shadow: 0px 5px 10px black;
}

@media (max-width: 800px) {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px 0 20px;
    height: 80px;
  }

  .header-text {
    color: #fff;
    font-size: 40px;
    display: none;
  }

  .header-img {
    width: 100%;
    height: auto;
  }

  .img-logo {
    padding: 20px 0;
  }
}

