.footer-container {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #F3F3F3;
  position: relative;
}

.footer-logo {
  width: 80px;
  height: 51px;
  z-index: 1000;
}

.span-copy {
  color: #868E9E;
  font-size: 14px;
  text-align: center;
  margin-top: 50px;
  z-index: 1000;
}

.footer-img-pulso {
  width: 37px;
  height: 36px;
}

.img-vetor-esquerda {
  width: 245px;
  height: 100px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.img-vetor-direita {
  width: 141px;
  height: 100px;
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (max-width: 800px) {
  .footer-container {
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f3f3f3;
  }

  .span-copy {
    color: #868E9E;
    font-size: 10px;
    text-align: center;
    margin-top: 10px;
  }
}